<template>
	<div class="loginpanel" style="padding-bottom: 64px;">
		<van-form @submit="onSubmit">
			<div class="headbannerbg">
			</div>
			<div style="padding:16px;">
				<van-field v-model="username" @focus="errors.username=false;" size="large" :error="errors.username"
					name="username" left-icon="user-o" placeholder="登录手机号" :required="true" />
				<van-field v-model="password" @focus="errors.password=false;" type="password" :error="errors.password"
					left-icon="shield-o" name="password" placeholder="密码" :required="true" />
				<div style="margin: 16px;">
					<van-button round block type="primary" native-type="submit">
						提交
					</van-button>
				</div>
			</div>
			<div style="padding:0px 32px;">
				<van-divider>OR</van-divider>
			</div>
			<div style="padding:0px 32px;font-size:13px;">
				<span style="float:left;">
					<router-link to="/changepasswd">忘记密码</router-link>
				</span>
				<span style="float:right;">
					<router-link to="/register">用户注册</router-link>
				</span>
			</div>
		</van-form>
	</div>
</template>

<script>
	import {
		setCookie
	} from '../util/index.js'
	import {
		Notify
	} from 'vant'

	export default {
		name: 'LoginPanel',
		emits: ['onLoadingData'],
		data() {
			return {
				username: '',
				password: '',
				loadingdata: false,
				errors: {
					username: false,
					password: false,
				},
			}
		},
		mounted() {
			//console.log(getCookie('vcuserid'));
		},
		components: {
			//BottomNav,
		},
		methods: {
			onSubmit(values) {
				//console.log(getCookie('vcuserid'));
				let that = this;
				if (!values.username) {
					this.errors.username = true;
				}
				if (!values.password) {
					this.errors.password = true;
				}

				if (this.errors.username || this.errors.password)
					return;

				this.loadingdata = true;
				this.$emit("onLoadingData", true);

				this.axios.post('api/?entry=user&op=dologin', values).then(function(res) {
					//console.log(res);
					setCookie('vcuseruid', res.data.UserUID, 30);
					setCookie('vcsessiontoken', res.data.SessionToken, 30);
					that.loadingdata = false;
					that.$emit("onLoadingData", false);
					if (res.data.rescode == 1)
						that.$router.push('/vcard/' + res.data.UserUID)
					else {
						Notify({
							type: 'danger',
							message: '登录失败，请检查用户名密码重试！'
						});
					}
				});
			}
		}
	}
</script>

<style>
	html,
	body {
		background-color: #FFF;
	}

	.wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.block {
		padding: 20px;
		border-radius: 8px;
		background-color: #fff;
	}

	.headbannerbg {
		width: 375px;
		height: 375px;
		background: url(~@/assets/images/loginbg.jpg) center top no-repeat;
		background-size: cover;
	}
</style>
