<template>
	<van-overlay v-model:show="loadingdata">
		<div class="wrapper" @click.stop>
			<div class="block">
				<van-loading type="spinner" color="#1989fa" vertical>数据加载中...</van-loading>
			</div>
		</div>
	</van-overlay>
  <router-view v-on:onLoadingData="onLoadingData"/>
</template>

<style>

</style>

<script>
	export default {
		data() {
			return {
				loadingdata: false,
			}
		},
		methods: {
			onLoadingData(data) {
				console.log(data);
				this.loadingdata = data;
			}
		},
	}
</script>