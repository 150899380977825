<template>
	<van-popup v-model:show="showimgeditor" :closeable="false" :overlay="true" position="bottom"
		:style="{height:'50%'}" :overlay-style="{opacity:0.2}">
		<div style="position: absolute;left:0px;top:0px;z-index: 10000;width:100%;">
			<div style="padding:8px;font-size:24px;text-align: right;">
				<van-icon name="success" @click="onExecCrop"></van-icon>
			</div>
		</div>
		<vue-picture-cropper :boxStyle="{
			width: '100%',
			height: '100%',
			backgroundColor: '#f8f8f8',
			margin: 'auto'
		}" :img="editingimg" :options="{
			viewMode: 1,
			dragMode: 'crop',
			aspectRatio: 1,
			crop: onCropperDone
		}" @ready="onCropperReady" />
	</van-popup>
	<div class="vcardeditor" style="padding-bottom: 48px;">
		<van-nav-bar title="编辑名片信息" left-text="返回" left-arrow @click-left="onClickLeft" />
		<div style="margin-top:12px;">
			<van-form @submit="onSubmit">
				<van-cell-group inset>
					<van-field name="headimage" input-align="center">
						<template #input>
							<van-uploader :after-read="afterRead">
								<van-image round width="60px" height="60px" :src="imageurl" />
							</van-uploader>
						</template>
					</van-field>
					<van-field v-model="username" label="登录手机" label-align="right" name="username" placeholder="登录手机号"
						:required="true" />
					<van-field name="gender" label-align="right" label="性别">
						<template #input>
							<van-radio-group direction="horizontal" v-model="gender">
								<van-radio name="1">先生</van-radio>
								<van-radio name="2">女士</van-radio>
							</van-radio-group>
						</template>
					</van-field>
					<van-field v-model="lastname" label="姓氏" label-align="right" name="lastname" placeholder="您的姓氏"
						:required="true" />
					<van-field v-model="firstname" label="名字" label-align="right" name="firstname" placeholder="您的名字"
						:required="true" />
					<van-field v-model="phone" label="固定电话" label-align="right" name="phone" placeholder="请填写固定电话"
						:required="false" />
					<van-field v-model="fax" label="传真" label-align="right" name="fax" placeholder="请填写传真号码"
						:required="false" />
					<van-field v-model="company" label="单位" label-align="right" name="company"
						placeholder="请输入您所在单位名称" />
					<van-field v-model="job" label="职位" label-align="right" name="job" placeholder="请输入您的职位" />
					<van-field v-model="email" label="邮件" label-align="right" name="email" placeholder="请输入您的邮件" />
					<van-field v-model="zipcode" label="邮编" label-align="right" name="zipcode" placeholder="请输入邮编" />
					<van-field v-model="fulladdress" label="地址" label-align="right" name="fulladdress"
						placeholder="请输入地址" />
					<van-field v-model="website" label="网址" label-align="right" name="website" placeholder="请填写网址"
						:required="false" />
					<van-field v-model="summary" label="签名" type="textarea" autosize label-align="right" name="summary"
						placeholder="请填签名" :required="false" />
					<div style="margin: 16px;">
						<van-button round block type="primary" native-type="submit">
							提交
						</van-button>
					</div>
				</van-cell-group>
			</van-form>
		</div>
		<van-tabbar v-if="this.vcuid" v-model="seltabbar">
			<van-tabbar-item icon="home-o" :to="'/vcard/' + this.vcuid">名片</van-tabbar-item>
			<van-tabbar-item icon="edit" to="/editvcard">编辑</van-tabbar-item>
			<van-tabbar-item icon="friends-o" to="/sharevcard">分享</van-tabbar-item>
			<van-tabbar-item icon="setting-o" @click="this.onExit();">退出</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	import {
		setCookie,
		getCookie,
		doResizeImage
	} from '../util/index.js'
	import {
		Notify
	} from 'vant'
	import VuePictureCropper, {
		cropper
	} from 'vue-picture-cropper'
	import {
		Dialog
	} from 'vant'

	export default {
		name: 'VCardEditor',
		emits: ['onLoadingData'],
		data() {
			return {
				username: '',
				lastname: '',
				firstname: '',
				phone: '',
				fax: '',
				company: '',
				job: '',
				gender: '',
				email: '',
				fulladdress: '',
				website: '',
				summary: '',
				zipcode: '',
				vcuid: '',
				cdata: '',
				headimage: '',
				imageurl: '',
				editingimg: '',
				himgupdated: false,
				showimgeditor: false,
				seltabbar: 1,
				curcookie: '',
			}
		},
		components: {
			//BottomNav,
			VuePictureCropper,
		},
		mounted() {
			this.curcookie = document.cookie;
			this.vcuid = getCookie('vcuseruid');
			if(this.vcuid == 'undefined')
				this.vcuid = '';
			
			if (!this.vcuid) {
				this.$router.push("/login");
				return;
			}
			let that = this;
			this.$emit("onLoadingData", true);
			this.axios.get('api/?entry=bcard&op=getvcinfo&vcardid=' + this.vcuid).then((response) => {
				that.cdata = response.data.vcardinfo;
				
				console.log(that.cdata);
				if(response.data.errno != 0) {
					that.$emit("onLoadingData", false);
					that.$router.push("/login");
					return;
				}

				//console.log(that.cdata);
				//document.title = this.cdata['LastName'] + this.cdata['FirstName'];
				that.username = that.cdata['Mobile'];
				that.lastname = that.cdata['LastName'];
				that.firstname = that.cdata['FirstName'];
				that.company = that.cdata['Company'];
				that.job = that.cdata['Job'];
				that.gender = that.cdata['Gender'];
				that.email = that.cdata['Email'];
				that.fulladdress = that.cdata['FullAddress'];

				if (that.cdata['HeadImage'])
					that.imageurl = 'api/' + that.cdata['HeadImage'];

				that.phone = that.cdata['Phone'];
				that.fax = that.cdata['Fax'];
				that.website = that.cdata['Website'];
				that.summary = that.cdata['Summary'];
				that.zipcode = that.cdata['ZipCode'];
				this.$emit("onLoadingData", false);
			});
		},
		methods: {
			onCropperReady() {
				//console.log(cropper.getDataURL());
			},
			onCropperDone() {
				//console.log(cropper.getDataURL());
			},
			onExecCrop() {
				this.imageurl = cropper.getDataURL();
				this.showimgeditor=false;
				this.himgupdated = true;
			},
			onExit() {
				Dialog.confirm({
						title: '退出确认',
						message: '即将退出登录，您确定吗？',
					})
					.then(() => {
						setCookie("vcuseruid", '');
						setCookie("vcsessiontoken", '');
						this.$router.push('/login');
					})
					.catch(() => {
						// on cancel
					});
				this.seltabbar = 1;
			},
			onSubmit(values) {
				values['himage'] = this.imageurl;

				console.log('start submit');

				values['vcuseruid'] = getCookie('vcuseruid');
				values['vcsessiontoken'] = getCookie('vcsessiontoken');
				values['himgupdated'] = this.himgupdated;
				this.$emit("onLoadingData", true);

				if (values['himage']) {
					let that = this;
					doResizeImage(values['himage'], 160, 160).then(function(val) {
						values['himage'] = val;
						//console.log(values);
						//console.log('submit then end');
						that.axios.post('api/?entry=user&op=savevcard', values).then(function(res) {
							//console.log(res);
							that.loadingdata = false;
							that.$emit("onLoadingData", false);
							if (res.data.rescode == 1) {
								Notify({
									type: 'success',
									message: '更新成功！',
									onClose: function() {
										that.$router.push('/vcard/' + that.vcuid);
									}
								});
							} else {
								Notify({
									type: 'danger',
									message: '资料更新失败，请查证后重试！'
								});
							}
						});
					});
				} else {
					//console.log(values);
					//console.log('submit end');

					let that = this;
					that.axios.post('api/?entry=user&op=savevcard', values).then(function(res) {
						console.log(res);
						that.loadingdata = false;
						that.$emit("onLoadingData", false);
						if (res.data.rescode == 1) {
							Notify({
								type: 'success',
								message: '更新成功！',
								onClose: function() {
									that.$router.push('/vcard/' + that.vcuid);
								}
							});
						} else {
							Notify({
								type: 'danger',
								message: '资料更新失败，请查证后重试！'
							});
						}
					});
				}
			},
			onClickLeft() {
				console.log(this.$router);
				this.$router.go(-1);
			},
			afterRead(res) {
				//console.log(res);
				//this.imageurl = res.content;
				//this.himgupdated = true;
				this.editingimg = res.content;
				this.showimgeditor=true;
			}
		}
	}
</script>

<style>
</style>
