import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import VCardDetail from '../views/VCardDetail.vue'
import VCardEditor from '../views/VCardEditor.vue'
import LoginPanel from '../views/LoginPanel.vue'

const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		meta: {
			title: '电子名片'
		}
	},
	{
		path: '/vcard/:vcuid',
		name: 'VCardDetail',
		component: VCardDetail,
		meta: {
			title: '电子名片'
		}
	},
	{
		path: '/register',
		name: 'RegisterPanel',
		component: () => import('../views/Register.vue'),
		meta: {
			title: '创建自己的电子名片'
		}
	},
	{
		path: '/login',
		name: 'LoginPanel',
		component: LoginPanel,
		meta: {
			title: '登录'
		}
	},
	{
		path: '/changepasswd',
		name: 'ChangePasswd',
		component: () => import('../views/ChangePasswd.vue'),
		meta: {
			title: '找回密码'
		}
	},
	{
		path: '/editvcard',
		name: 'VCardCreator',
		component: VCardEditor,
		meta: {
			title: '创建电子名片'
		}
	},
	{
		path: '/editvcard/:vcuid',
		name: 'VCardEditor',
		component: VCardEditor,
		meta: {
			title: '编辑电子名片'
		}
	},
	{
		path: '/sharevcard',
		name: 'ShareVCard',
		component: () => import('../views/ShareVCard.vue'),
		meta: {
			title: '分享电子名片'
		}
	}
]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})

export default router
