import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios';
import VueAxios from 'vue-axios'
import Vant from 'vant';
import 'vant/lib/index.css';

const LANGUAGES = {
	en: require('@/assets/local/en-us.json'),
	chs: require('@/assets/local/zh-cn.json')
};

let VueApp = createApp(App);

VueApp.config.globalProperties.LANGUAGES = LANGUAGES; 
VueApp.config.globalProperties.LOCAL = LANGUAGES['chs'];

router.beforeEach((to, from, next) => {
	if(to.meta && to.meta.title) {
		document.title = to.meta.title;
	}
	next();
})

//console.log(LANGUAGES);
VueApp.use(router).use(VueAxios, axios).use(Vant).mount('#app');
