//获取cookie、
export function getCookie(name) {
	var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
	arr = document.cookie.match(reg);
	if (arr)
		return (arr[2]);
	else
		return false;
}

//设置cookie,增加到vue实例方便全局调用
export function setCookie(c_name, value, expiredays) {
	var exdate = new Date();
	exdate.setDate(exdate.getDate() + expiredays);
	document.cookie = c_name + "=" + escape(value) + ((expiredays == null) ? "" : ";expires=" + exdate.toGMTString());
}

//删除cookie
export function delCookie(name) {
	var exp = new Date();
	exp.setTime(exp.getTime() - 1);
	var cval = getCookie(name);
	if (!cval)
		document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}

function resizeImage(url, maxwidth = 800, maxheight = 800, opt = 'contain') {
	var img = new Image(); //提供给canvas使用
	img.src = url;
	return new Promise((resolve, reject) => {
		img.onload = (image) => {
			console.log(image)
			var canvas = document.createElement('canvas');
			let context = canvas.getContext('2d');

			var img_width = maxwidth,
				img_height = maxheight;
			if (opt == 'contain') {
				if (img.width * img_height >= img.height * img_width) {
					if (img.width > img_width) {
						//需要缩放
						img_height = (img.height * img_width) / img.width;
					} else {
						resolve(url);
						return;
					}
				} else {
					if (img.height > img_height) {
						//需要缩放
						img_width = (img.width * img_height) / img.height;
					} else {
						resolve(url);
						return;
					}
				}
			}
			console.log(img_width + '---' + img_height);

			canvas.width = img_width;
			canvas.height = img_height;
			context.drawImage(img, 0, 0, img_width, img_height);
			let dataret = canvas.toDataURL('image/jpeg');
			
			//console.log(dataret);
			
			resolve(dataret);
		}
		
		img.onerror = (image) => {
			console.log(image)
			reject(img);
		}
	});
}

export async function doResizeImage(url, maxwidth = 800, maxheight = 800, opt = 'contain') {
	let retdata = await resizeImage(url, maxwidth, maxheight, opt);
	
	return retdata;
}

export function getIsWxClient() {
	var ua = navigator.userAgent.toLowerCase();
	if (ua.match(/MicroMessenger/i) == "micromessenger") {
		return true;
	}
	return false;
}