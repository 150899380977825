<template>
	<van-dialog v-model:show="showqrcodeboard" title="长按识别二维码添加到通讯录" show-cancel-button>
		<div style="text-align: center;" v-if="this.isWeChat">
			<img :src="qrcodeimageurl" style="width:240px;height:240px;" />
		</div>
		<div style="text-align: center;" v-if="!this.isWeChat">
			<div style="padding:12px;text-align: left;line-height:26px;">
				请点击“下载通讯录”下载并打开通讯录文件
			</div>
			<div style="padding:0 12px 24px 12px;">
				<van-button icon="down" type="primary" :url="this.CVFileUrl">下载通讯录</van-button>
			</div>
		</div>
	</van-dialog>
	<van-popup v-model:show="showstyleboard" :closeable="false" :overlay="true" position="bottom"
		:style="{height:'50%'}" :overlay-style="{opacity:0.2}">
		<van-radio-group v-model="cardstyle.cardbgcolor">
			<van-card centered @click="this.cardstyle.cardbgcolor = '#1f4e82'">
				<template #thumb>
					<div style="width:158px;height:88px; background-color: #1f4e82; border-radius:5px;"></div>
				</template>
				<template #title>
					<div style="text-align: right; float:right;padding-right:12px;">
						<van-radio name="#1f4e82"></van-radio>
					</div>
				</template>
			</van-card>
			<van-card centered @click="this.cardstyle.cardbgcolor = '#5d1f82'">
				<template #thumb>
					<div style="width:158px;height:88px; background-color: #5d1f82; border-radius:5px;"></div>
				</template>
				<template #title>
					<div style="text-align: right; float:right;padding-right:12px;">
						<van-radio name="#5d1f82"></van-radio>
					</div>
				</template>
			</van-card>
			<van-card centered @click="this.cardstyle.cardbgcolor = '#82531f'">
				<template #thumb>
					<div style="width:158px;height:88px; background-color: #82531f; border-radius:5px;"></div>
				</template>
				<template #title>
					<div style="text-align: right; float:right;padding-right:12px;">
						<van-radio name="#82531f"></van-radio>
					</div>
				</template>
			</van-card>
			<van-card centered @click="this.cardstyle.cardbgcolor = '#821f30'">
				<template #thumb>
					<div style="width:158px;height:88px; background-color: #821f30; border-radius:5px;"></div>
				</template>
				<template #title>
					<div style="text-align: right; float:right;padding-right:12px;">
						<van-radio name="#821f30"></van-radio>
					</div>
				</template>
			</van-card>
		</van-radio-group>
	</van-popup>
	<van-popup v-model:show="showeditcardboard" :closeable="false" :overlay="true" position="bottom"
		:style="{ height: '50%' }" :overlay-style="{opacity: 0.2}">
		<div>
			<h2 class="boardtitle">副标题选择</h2>
			<div style="padding:12px;">
				<van-radio-group v-model="this.cardstyle.subheadfield">
					<van-cell-group>
						<van-cell title="显示公司名" clickable @click="this.cardstyle.subheadfield = 'Company'">
							<template #right-icon>
								<van-radio name="Company" />
							</template>
						</van-cell>
						<van-cell title="显示职位" clickable @click="this.cardstyle.subheadfield = 'Job'">
							<template #right-icon>
								<van-radio name="Job" />
							</template>
						</van-cell>
					</van-cell-group>
				</van-radio-group>
			</div>
			<h2 class="boardtitle">联系方式</h2>
			<div style="padding:12px;">
				<van-checkbox-group v-model="this.cardstyle.cardfields" max="4">
					<van-cell-group>
						<van-cell v-for="(item, index) in this.cardstyle.contactfields" clickable
							:key="item+'_' + index" :title="this.LANG[item]" @click="toggle(index)">
							<template #right-icon>
								<van-checkbox :name="item" @click.stop />
							</template>
						</van-cell>
					</van-cell-group>
				</van-checkbox-group>
			</div>
		</div>
	</van-popup>
	<div class="vcarddetail" style="padding-bottom: 64px;">
		<section class="cardframe">
			<div class="cardinfo" :class="this.cardstyle.isrounded?'cardrounded':''"
				:style="{backgroundColor: this.cardstyle.cardbgcolor, backgroundImage: (this.cardstyle.cardbgimage?('url(' + this.cardstyle.cardbgimage + ')'):'')}">
				<div class="avatorwraper">
					<van-image round :src="this.avatar" class="avatorimg" />
				</div>
				<div class="cardtitle">
					<h1>{{this.cdata.LastName}}{{this.cdata.FirstName}}</h1>
					<h2>{{this.cdata[this.cardstyle.subheadfield]}}</h2>
				</div>
				<div class="contactinfowraper">
					<ul class="contactinfo">
						<template v-for="(key) in this.cardstyle.cardfields" v-bind:key="key">
							<li v-if="(key=='Mobile' || key=='Phone') && this.cdata[key]"
								v-show="this.cdata[key]?true:false"><label>{{this.LANG[key]}}</label><a
									:href="'tel:' +this.cdata[key]">{{this.cdata[key]}}</a></li>
							<li v-else-if="key=='Email' && this.cdata[key]" v-show="this.cdata[key]?true:false">
								<label>{{this.LANG[key]}}</label><a
									:href="'mailto:' +this.cdata[key]">{{this.cdata[key]}}</a>
							</li>
							<li v-else-if="this.cdata[key]" v-show="this.cdata[key]?true:false">
								<label>{{this.LANG[key]}}</label>{{this.cdata[key]}}
							</li>
						</template>
					</ul>
				</div>
			</div>
		</section>
		<section class="actionpanel" v-if="this.ownthiscard">
			<van-cell-group inset>
				<div class="opinner">
					<van-button icon="edit" @click="onEditCard" style="margin-right:16px;" size="small">设置
					</van-button>
					<van-button icon="setting-o" size="small" @click="onStyleSettings">风格</van-button>
				</div>
			</van-cell-group>
		</section>
		<section class="profile">
			<van-cell-group inset>
				<ul>
					<li v-for="(item) in this.resinfolist" v-bind:key="item"
						v-show="item.fields && item.fields.length > 0 && this.cdata[item.fields[0]]">
						<label class="icon">
							<van-icon :name="item.icon"></van-icon>
						</label><span>
							<template v-for="subitem in item.fields" v-bind:key="subitem">
								<span v-if="subitem=='Mobile' || subitem=='Phone'"><a
										:href="'tel:' + this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else-if="subitem=='Website'"><a
										:href="this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else-if="subitem=='Email'"><a
										:href="'mailto:' + this.cdata[subitem]">{{this.cdata[subitem]}}</a></span>
								<span v-else>{{this.cdata[subitem]}}</span>
								<i>{{this.LANG[subitem]}}</i>
							</template>
						</span>
					</li>
				</ul>
			</van-cell-group>
		</section>
		<van-tabbar v-if="this.loginvcuid" v-model="seltabbar">
			<van-tabbar-item icon="home-o" :to="'/vcard/' + this.loginvcuid">名片</van-tabbar-item>
			<van-tabbar-item icon="edit" to="/editvcard">编辑</van-tabbar-item>
			<van-tabbar-item icon="friends-o" to="/sharevcard">分享</van-tabbar-item>
			<van-tabbar-item icon="setting-o" @click="this.onExit();">退出</van-tabbar-item>
		</van-tabbar>
		<van-action-bar v-if="!this.loginvcuid">
			<van-action-bar-button v-if="!this.ownthiscard && !this.loginvcuid" to="/editvcard" icon="edit"
				type="primary" text="创建我的名片" />
			<van-action-bar-button v-if="!this.ownthiscard" icon="plus" type="success" @click="	this.onAddBCard();"
				text="添加到通讯录" />
		</van-action-bar>
	</div>
</template>
<style>
	html,
	body {
		background-color: #f7f8fa;
		color: #323233;
	}

	a,
	a:hover,
	a:focus {
		color: #323233;
	}

	.cardframe {
		padding: 16px;
	}

	.cardinfo {
		display: block;
		position: relative;
		background-size: cover;
		background-position: center;
		height: 208px;
	}

	.cardtitle {
		padding-left: 26px;
		padding-top: 34px;
		letter-spacing: 2px;
		text-align: left;
		color: #fffef8;
	}

	.cardtitle h1,
	.cardtitle h2 {
		margin: 0px;
		padding: 0px;
		line-height: 1.5em;
		font-weight: normal;
	}

	.cardtitle h1 {
		font-size: 22px;
	}

	.cardtitle h2 {
		font-size: 14px;
	}

	.contactinfowraper {
		padding-left: 26px;
		height: 110px;
		display: table-cell;
		width: 300px;
		vertical-align: middle;
	}

	.contactinfo {
		list-style: none;
		color: #fffef8;
		line-height: 1.8em;
		font-size: 12px;
		margin: 0px;
		padding: 0px;
		display: inline-block;
	}

	.contactinfo li {
		margin: 0px;
		padding: 0px;
	}

	.contactinfo li label:after {
		content: '：';
	}

	.contactinfo li a {
		color: #fffef8;
	}

	.cardrounded {
		border-radius: 8px;
	}

	.avatorwraper {
		display: block;
		right: 24px;
		top: 32px;
		position: absolute;
	}

	.avatorimg {
		width: 60px;
		height: 60px;
	}

	.vcarddetail {
		padding-bottom: 20px;
	}

	.profile ul {
		margin: 0px;
		padding: 8px;
	}

	.profile ul li {
		margin: 0px;
		padding: 8px;
		list-style: none;
		border-bottom: 1px solid #EEE;
	}

	.profile ul li:last-child {
		border: none;
	}

	.profile ul li label,
	.profile ul li span {
		display: table-cell;
		vertical-align: middle;
		vertical-align: top;
		color: #666;
	}

	.profile ul li span {
		font-size: 13px;
	}

	.profile ul li label {
		font-size: 28px;
		padding-right: 18px;
		text-align: center;
	}

	.profile ul li span i {
		display: inline-block;
		padding-bottom: 5px;
		line-height: 1em;
		font-size: 11px;
		color: #AAA;
		font-style: normal;
	}

	.oppanel {
		padding: 16px 0;
		margin-bottom: 32px;
	}

	.actionpanel {
		padding: 16px 0;
	}

	.actionpanel .opinner {
		padding: 16px;
		text-align: center;
	}

	.boardtitle {
		margin: 12px;
		padding: 0px;
		line-height: 32px;
		font-size: 13px;
		font-weight: normal;
		padding-left: 12px;
		color: #999;
		background-color: #F7F7F7;
		border-radius: 16px;
	}
</style>

<script>
	//import BottomNav from "../components/BottomNav.vue"
	import {
		setCookie,
		getCookie,
		getIsWxClient,
	} from '../util/index.js'

	import {
		Dialog
	} from 'vant'

	export default {
		name: 'VCardDetail',
		emits: ['onLoadingData'],
		components: {
			//BottomNav,
		},
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				showqrcodeboard: false,
				qrcodeimageurl: '',
				ownthiscard: false,
				tagpageindex: 0,
				resinfolist: [],
				loginvcuid: '',
				cardstyle: {
					isrounded: true,
					cardbgcolor: '#1f4e82',
					cardbgimage: 'static/images/vcardbg1.png',
					subheadfield: 'Company',
					cardfieldstitle: true, //是否显示标题
					cardfields: ['Mobile', 'Email', 'FullAddress'],
					detailfields: [{
						'icon': 'phone-o',
						'fields': ['Mobile', 'Phone', 'Fax']
					}, {
						'icon': 'free-postage',
						'fields': ['Email']
					}, {
						'icon': 'cluster-o',
						'fields': ['Company']
					}, {
						'icon': 'location-o',
						'fields': ['FullAddress']
					}, {
						'icon': 'aim',
						'fields': ['ZipCode']
					}, {
						'icon': 'guide-o',
						'fields': ['Website']
					}, {
						'icon': 'comment-o',
						'fields': ['Summary']
					}],
					contactfields: ['Mobile', 'Phone', 'Fax', 'Email', 'FullAddress', 'ZipCode', 'Website']
				},
				vcuid: '',
				cdata: 0,
				avatar: 'static/images/avatar.png',
				LANG: 0,
				isWeChat: false,
				CVFileUrl: '',
				showeditcardboard: false,
				showstyleboard: false,
				activedtab: 0,
				seltabbar: 0,
			}
		},
		beforeRouteUpdate(to, from, next) {
			if (to.fullPath != from.fullPath) {
				next();
				console.log(to.params.vcuid);
				this.vcuid = to.params.vcuid;
				this.refreshPageInfo();
			}
		},
		created() {
			window.addEventListener('resize', this.handleResize);
		},
		methods: {
			onStyleSettings() {
				this.showstyleboard = true;
			},
			handleResize() {
				this.clientWidth = document.documentElement.clientWidth;
				this.clientHeight = document.documentElement.clientHeight;
			},
			onAddBCard() {
				console.log('clicked');
				if (this.isWeChat)
					this.showqrcodeboard = true;
				else
					document.location = this.CVFileUrl;
			},
			onEditCard() {
				//this.cardstyle.subheadfield = 'Job';
				//this.cardstyle.cardfields = ['Mobile', 'Fax', 'Email', 'FullAddress'];
				this.showeditcardboard = true;
			},
			onCardStyleUpdated() {
				if (!this.timercs) {
					this.timercs = true;
					let that = this;
					setTimeout(function() {
						that.timercs = false;
						let postobj = new Object();

						postobj.cardstyle = that.cardstyle;
						postobj.vcuseruid = getCookie("vcuseruid");
						postobj.vcsessiontoken = getCookie("vcsessiontoken");
						console.log(postobj);
						that.axios.post('api/?entry=user&op=savevcstyle', postobj).then((response) => {
							console.log(response);
						});
					}, 500)
				}
			},
			onExit() {
				Dialog.confirm({
						title: '退出确认',
						message: '即将退出登录，您确定吗？',
					})
					.then(() => {
						setCookie("vcuseruid", '');
						setCookie("vcsessiontoken", '');
						this.$router.push('/login');
					})
					.catch(() => {
						// on cancel
					});
				this.seltabbar = 0;
			},
			refreshPageInfo() {
				this.ownthiscard = ((this.loginvcuid && this.loginvcuid == this.vcuid) ? true : false);
				this.$emit("onLoadingData", true);
				this.axios.get('api/?entry=bcard&op=getvcinfo&vcardid=' + this.vcuid).then((response) => {
					//console.log(response);
					this.cdata = response.data.vcardinfo;
					
					if(response.data.errno != 0) {
						this.$emit("onLoadingData", false);
						this.$router.push("/login");
						return;
					}

					document.title = this.cdata['LastName'] + this.cdata['FirstName'];

					if (this.cdata['HeadImage'])
						this.avatar = 'api/' + this.cdata['HeadImage'];

					if (this.cdata['Settings'])
						this.cardstyle = JSON.parse(this.cdata['Settings']);

					this.resinfolist = [];

					for (var key in this.cardstyle.detailfields) {
						var infitem = this.cardstyle.detailfields[key];
						var showitem = Array();
						showitem.icon = infitem.icon;
						showitem.fields = Array();

						for (var subkey in infitem.fields) {
							var infsubitem = infitem.fields[subkey];

							if (!this.cdata[infsubitem])
								continue;

							showitem.fields[subkey] = infsubitem;
						}

						if (showitem.fields.length <= 0)
							continue;
						this.resinfolist.push(showitem);
					}
					//console.log(this.resinfolist);
					this.$emit("onLoadingData", false);
				});
			}
		},
		watch: {
			'cardstyle.cardbgcolor'(val) {
				console.log(val);
				this.cardstyle.cardbgcolor = val;
				this.onCardStyleUpdated();
			},
			'cardstyle.cardfields'(val) {
				console.log(val);
				this.onCardStyleUpdated();
			},
			'cardstyle.subheadfield'(val) {
				console.log(val);
				this.onCardStyleUpdated();
			},
			clientWidth(val) {
				// 为了避免频繁触发resize函数导致页面卡顿，使用定时器
				if (!this.timerw) {
					// 一旦监听到的clientWidth值改变，就将其重新赋给data里的clientWidth
					this.clientWidth = val
					this.timerw = true
					let that = this
					setTimeout(function() {
						that.timerw = false;
					}, 100)
				}
			},
			clientHeight(val) {
				if (!this.timerh) {
					// 一旦监听到的clientHeight值改变，就将其重新赋给data里的clientHeight
					this.clientHeight = val
					this.timerh = true
					let that = this
					setTimeout(function() {
						that.timerh = false;
					}, 100)
				}
			}
		},
		mounted() {
			this.loginvcuid = getCookie('vcuseruid');
			if(this.loginvcuid == 'undefined')
				this.loginvcuid = '';
			this.vcuid = this.$route.params.vcuid;
			this.ownthiscard = ((this.loginvcuid && this.loginvcuid == this.vcuid) ? true : false);
			this.LANG = this.LOCAL['VCardDetail'];
			this.isWeChat = getIsWxClient();
			this.CVFileUrl = 'api/?entry=bcard&op=getvcard&vcardid=' + this.vcuid + '&span=' + Date.now();

			this.qrcodeimageurl = 'api/?entry=bcard&op=getvcode&vcardid=' + this.vcuid;

			this.refreshPageInfo();
		}
	}
</script>
