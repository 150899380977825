<template>
	<div class="home">
		我也要一个电子名片
	</div>
</template>

<script>
	// @ is an alias to /src
//	import HelloWorld from '@/components/HelloWorld.vue'

	export default {
		name: 'Home',
		components: {
		},
		mounted() {
			this.$router.push('/login');
		}
	}
</script>
